<template>
  <div class="manage-companies">
    <h2>{{ $t("manageCompanies") }}</h2>
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
      multi-sort
      class="mt-8 elevation-0"
      style="margin-bottom: 53px"
    >
      <!-- Top Toolbar -->
      <template v-slot:top>
        <v-toolbar flat class="data-table-top-layout">
          <!-- Create Company Dialog -->
          <v-dialog
            v-model="formDialog"
            persistent
            max-width="500px"
            @click:outside="closeDialogs"
          >
            <template v-slot:activator="{ on }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  color="white--text"
                  v-on="on"
                  :style="{ 'background-color': hover ? '#31649B' : '#424242' }"
                  style="text-transform: unset !important"
                  >{{ $t("createCompany") }}</v-btn
                >
              </v-hover>
            </template>
            <v-card>
              <v-container class="pa-4">
                <v-row class="mb-4">
                  <v-card-title class="text-h5">{{formTitle}}</v-card-title>
                  <v-spacer />
                  <v-btn
                    icon
                    color="primary"
                    class="mt-3"
                    @click="closeDialogs"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-row>
                <v-divider />
                <v-form @submit.prevent="formSubmit" class="mx-2">
                  <v-row>
                    <v-col cols="12" class="form-layout">
                      <v-img
                        v-if="
                         itemImage !== null
                        "
                        :src="itemImage"
                        aspect-ratio="3"
                        contain
                        class="mt-4 mb-n4"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <a-text-field
                        v-model="$v.formInputName.$model"
                        :validator="$v.formInputName"
                        :label="$t('name') + $t('required')"
                        class="mt-4"
                      />
                      <v-file-input
                        class="mt-4"
                        v-model="formInputLogo"
                        show-size
                        small-chips
                        :label="$t('logo')"
                        hide-details="auto"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-card-actions class="mt-4 mr-n4">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="closeDialogs"
                      style="text-transform: unset !important"
                    >
                      {{$t('close')}}
                    </v-btn>
                    <v-btn
                      color="primary"
                      type="submit"
                      class="mr-2"
                      style="text-transform: unset !important"
                    >
                      {{$t('save')}}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-container>
            </v-card>
          </v-dialog>
          <!-- !Create Company Dialog -->
          <v-spacer />
          <v-col cols="3">
            <a-text-field
              v-model="search"
              :label="$t('search')"
              append-icon="mdi-magnify"
            ></a-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <!-- !Top Toolbar -->
      <!-- Customize Columns -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="data-table-actions-layout">
          <v-hover v-slot="{ hover }">
            <v-btn
              small
              color="white--text"
              class="mr-2 mt-2 mb-2"
              :style="{ 'background-color': hover ? '#31649B' : '#424242' }"
              style="text-transform: unset !important"
              @click.stop="editItem(item)"
            >
              <v-icon small left>mdi-pencil</v-icon>
               {{$t('edit')}}
            </v-btn>
          </v-hover>
          <v-btn
            small
            class="mt-2 mb-2"
            color="red lighten-1 white--text"
            style="text-transform: unset !important"
            @click.stop="deleteItem(item)"
          >
            <v-icon small left>mdi-delete</v-icon>
             {{$t('delete')}}
          </v-btn>
        </div>
      </template>
      <!-- !Customize Columns -->
    </v-data-table>
    <!-- Delete Item Dialog -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="500px"
      @click:outside="closeDialogs"
    >
      <v-card>
        <v-container class="pa-4">
          <v-row class="mb-4">
            <v-card-title class="text-h5">{{$t('delete')}}</v-card-title>
            <v-spacer />
            <v-btn icon color="primary" class="mt-3" @click="closeDialogs">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-divider />
          <div class="mx-4">
            <p class="my-6">{{$t('confirmCompanyDelete')}}</p>
            <div class="form-layout mx-2">
              <v-row>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('name')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('logo')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text"
                    ><v-img
                      v-if="itemImage !== null"
                      :src="itemImage"
                      aspect-ratio="3"
                      contain
                    ></v-img>
                  </span>
                </v-col>
              </v-row>
            </div>
            <v-card-actions class="mt-4 mr-n2">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeDialogs"
                style="text-transform: unset !important"
              >
                {{$t('close')}}
              </v-btn>
              <v-btn
                color="red lighten-1 white--text"
                style="text-transform: unset !important"
                class="mr-2"
                @click="deleteItemConfirm"
              >
                {{$t('delete')}}
              </v-btn>
            </v-card-actions>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- !Delete Item Dialog -->
  </div>
</template>
<script>
import AppError from "@/utils/AppError.js";
import ATextField from "@/components/ATextField.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "manage-companies",

  components: {
    ATextField,
  },

  mounted: function () {
    this.loadData();
  },

  mixins: [validationMixin],
  validations: {
    // Form validations
    formInputName: { required },
  },

  data() {
    return {
      // Dialogs
      formDialog: false,
      deleteDialog: false,

      // Data table
      search: null,
      selectedIndex: -1,

      // Data
      data: [],

      // Form data
      formInputName: null,
      formInputLogo: null,
      itemImage: null,
    };
  },

  computed: {
    // Return the form title depending on the index.
    formTitle() {
      return this.selectedIndex === -1
        ? this.$t("createCompany")
        : this.$t("editCompany");
    },
    headers() {
      return [
        { text: this.$t("name"), value: "name" },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "center",
          filterable: false,
          sortable: false,
          width: 300,
        },
      ];
    },
  },

  methods: {
    /**
     * Get the companies.
     * @public
     */
    loadData() {
      // Load form data
      const loadFormData = this.$api({
        url: this.$config.api.route.companies,
        method: "GET",
      }).then((resp) => {
        this.data = resp.data;
      });
      // Async handle loads
      this.$store.dispatch("util/startLoading");
      Promise.all([loadFormData])
        .catch(() => {
          AppError.handle(new AppError("LOAD_DATA_UNKNOWN"));
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Set the edit dialog.
     * @param {company} item - the company.
     * @public
     */
    editItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.setForm(item);
      this.formDialog = true;
    },
    /**
     * Set the delete dialog.
     * @param {company} item - the company.
     * @public
     */
    deleteItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.setForm(item);
      this.deleteDialog = true;
    },
    /**
     * Close all the dialogs.
     * @public
     */
    closeDialogs() {
      this.formDialog = false;
      this.deleteDialog = false;
      
    },
    resetInputFields(){
      this.$nextTick(() => {
        this.resetForm();
        this.selectedIndex = -1;
      });
    },
    /**
     * Set the company details.
     * @param {company} item - the company.
     * @public
     */
    setForm(item) {
      this.formInputName = item.name;
      if (item.logo) {
        this.itemImage = item.logo;
      } else {
        this.itemImage = null;
      }
    },
    /**
     * Reset the form dialog.
     * @public
     */
    resetForm() {
      this.formInputName = null;
      this.formInputLogo = null;
      this.$v.$reset();
    },
    /**
     * Create or edit a company.
     * @public
     */
    formSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const item = this.data[this.selectedIndex];
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.companies;
      if (item !== undefined) {
        url = this.$config.api.route.companiesAction;
        url = url.replace(":id", item.id);
      }

      const formData = new FormData();
      formData.append("name", this.formInputName);
      if (this.formInputLogo) {
        formData.append("logo", this.formInputLogo);
      }

      this.closeDialogs();

      this.$api({
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: item === undefined ? "POST" : "PUT",
      })
        .then(() => {
          this.resetInputFields();
          this.loadData();
          this.$store.dispatch(
            "alert/showSuccess",
            item === undefined
              ? this.$t('companyCreated')
              : this.$t('companyEdited')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Delete company.
     * @public
     */
    deleteItemConfirm() {
      const item = this.data[this.selectedIndex];

      this.closeDialogs();

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.companiesAction;
      url = url.replace(":id", item.id);
      this.$api({
        url: url,
        method: "DELETE",
      })
        .then(() => {
          
          this.resetInputFields();
          this.loadData();
          this.$store.dispatch(
            "alert/showSuccess",
            this.$t('companyDeleted')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
  },
};
</script>
